<template>
  <div id="preview">
    <div class="inner">
      <iframe style="width: 100%; height: 1120px;" src="https://ditto.fm/police-state"></iframe>
      <!-- <div class="image fit">
        <img src="images/police-state.jpg" alt="Police State by Shutterwax" />
      </div> -->
      <div class="content">
        <header>
          <h2>Police State</h2>
        </header>

        <iframe style="border: 0; width: 100%; height: 42px;" src="https://bandcamp.com/EmbeddedPlayer/track=1160114654/size=small/bgcol=ffffff/linkcol=0687f5/transparent=true/" seamless><a href="https://shutterwax.bandcamp.com/track/police-state">Police State by Shutterwax</a></iframe>
        <p>What happens after we all pull through?</p>
      </div>
    </div>
    <router-link :to="{ name: 'Home', params: {} }" class="nav previous">
      <span class="fa fa-chevron-left"></span>
    </router-link>
    <router-link :to="{ name: 'Jeff', params: {} }" class="nav next">
      <span class="fa fa-chevron-right"></span>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'PoliceStatePage',
  components: {

  },
  mounted() {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
